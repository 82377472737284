.card object {
    width: 100%;
    filter: invert(1) hue-rotate(222deg) saturate(2) contrast(.815);
    text-align: center;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
}

.user {
    font-family: "Helvetica", Arial, sans-serif;
    width: 265px;
    height: 300px;
    margin-bottom: 50px;
    overflow: hidden;
    border-radius: 6px;
    background-color: var(--black);
    text-align: center;
    color: var(--light);
    font-weight: 100;
    transition: background 1000ms ease-out;
    box-shadow: 0 0 1em black;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
}

.user dl,
.user dd {
    margin: 0;
}

.user dt {
    display: none;
}

.user-data {
    background: var(--main) url('../../images/github.png') no-repeat 5px 5px;
    background-size: 25px;
    height: 85px;
    border: 1px solid var(--main);
    border-bottom:0;
}

dd.user-avatar {
    display: inline-block;
    margin: 20px 0 10px;
}

.user-avatar img {
    border-radius: 100%;
    height: 120px;
    width: 120px;
    border: 4px solid var(--main);
    vertical-align: middle;
    background-color: var(--main);
}

dd.user-name,
dd.user-account {
    margin: 5px 0;
}

.user-name {
    font-size: 24px;
}

.user-account {
    font-size: 16px;
    color: var(--light);
    margin: 5px 0;
}

.user-stats {
    border-top: 1px groove var(--light);
    position: relative;
    top: 155px;
}

.user-stats dd {
    padding: 10px 20px;
}

.user-repos,
.user-following,
.user-followers {
    display: inline-block;
    font-size: 22px;
    color: var(--light);
}

.user-repos:after,
.user-following:after,
.user-followers:after {
    content: attr(data-stats);
    text-transform: uppercase;
    display: block;
    font-size: 11px;
    color: var(--grey);
    font-weight: normal;
    line-height: 1.7em;
}

a.user-github-url,
a.user-repos-url,
a.user-followers-url {
    text-decoration: none;
}

.description {
    margin-top: 22vh;
    max-width: 100vw !important;
    border: none !important;
    display: grid;
    line-height: 160%;
    letter-spacing: -1.709px;
}

.description span:first-child {
    margin-top: 0em !important;
}

.description span {
    margin-top: 1em;
    user-select: text;
    text-align: justify;
}

.description span a {
    text-decoration: none;
    border-bottom: 2px solid var(--light);
    padding-left: 3px;
    padding-right: 3px;
    transition: all.2s;
}

.description span a:hover {
    color: var(--black);
    background-color: var(--light);
    border-radius: 4px;
}