h1 {
    text-align: center;
}

.studies-ctn {
    width: 100%;
    max-width: 1400px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 5vw;
    margin-left: auto;
    margin-right: auto;
}

.studies-ctn div {
    position: relative;
    width: 650px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    background-position: 72%;
    background-size: cover;
}

.text h2 {
    font-size: 2em;
    padding: 4px;
    color: var(--light);
}

.studies-ctn div .text {
    background-color: rgb(0,0,0,.4);
    backdrop-filter: blur(2em);
    bottom: 0;
    margin: 0;
    padding: 4px;
    width: calc(100% - 8px);
}

.text p {
    text-align: justify !important;
}

.text a {
    position: absolute;
    text-decoration: none;
}

.text a:hover {
    border-bottom: 2px solid var(--main);
}

@media only screen and (max-width: 450px) {

    .studies-ctn div {
        height: auto;
        border-radius: 17px;
        box-shadow: 0 0 1em var(--main);
    }

    .studies-ctn div .text {
      font-size: .9em;
    }

    .text img {
        opacity: .4;
        width: calc(100% + 8px);
        height: auto;
        margin-left: -4px;
    }

    .text p {
        padding-bottom: 1.5em;
    }

    .text a {
        bottom: 9px;
        right: 9px;
        padding-left: 4px;
        padding-right: 4px;
        color: var(--main);
        border-bottom: 2px solid transparent;
    }
    
    .pch {
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 450px) {

    .studies-ctn div {
        height: 555px;
        margin-bottom: 50px;
        box-shadow: 0 0 1em black;
    }

    .studies-ctn div .text {
      font-size: 1.2em;
      height: auto;
    }

    .text img {
        display: none;
    }

    .text p {
        line-height: 0%;
        opacity: 0;
        transition: all.2s;
    }
    
    .text a {
        color: var(--light);
        background-color: var(--main);
        bottom: 0px;
        right: 0px;
        padding: 9px;
    }
    
    .text a:hover {
        color: var(--main);
        background-color: var(--light);
    }

    .studies-ctn div:hover .text p {
        line-height: 100%;
        opacity: 1;
        font-size: 1.1em;
        padding-bottom: 1.5em;
    }

    .esilv {
        background-image: url('../../images/esilv.jpg');
    }
    
    .pch {
        background-image: url('../../images/pch.jpg');
    }
    
    .studies-ctn div .text {
        position: absolute;
    }

}  