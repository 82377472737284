.page {
    padding-top: 15vh;
    height: auto;
    width: 100%;
}

h1 {
    text-align: center;
}

.resume {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    background-image: url('../../images/sunil.png');
    background-repeat: no-repeat;
    border-radius: 25px;
}

.doc {
    height: auto;
    width: 85%;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4vw;
    border: .4vw solid var(--main-dark);
    background-color: var(--main-light);
    box-shadow: 2px 2px 60px rgb(4, 4, 4, .17);
    color: var(--main-dark);
}

.cv-img {
    margin: 2%;
    width: 11%;
    height: auto;
}

.cv-info {
    position: relative;
    text-align: center;
    width: 100%;
    line-height: 4vh;
    font-style: italic;
    margin-left: auto;
    margin-right: auto;
}

.cv-link:hover {
    color: var(--main);
}

#cv-title {
    text-align: left;
    color: var(--main-dark);
    font-weight: bolder;
    width: 100%;
    border-bottom: .33vh dotted;
    user-select: none;
    transition: all.4s;
}

#cv-title:hover {
    color: var(--main);
}

#cv-title:hover .cv-span {
    color: var(--main);
    padding-right: 9%;
}

.cv-span {
    font-size: 1.4em;
    border-bottom: .33vh solid;
    padding-right: 4%;
    transition: all.4s;
}

.cv-txt {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    white-space: pre-wrap;
}

.cv-txt a {
    text-decoration: none;
}

.cv-txt span:hover {
    cursor: text !important;
}

.cv-drop {
    text-align: left;
    margin-top: -5vh;
    pointer-events: none;
    transition: all .4s ease .1s, opacity .1s;
    opacity: 0;
    vertical-align: middle;
    font-size: 0vh;
    flex-wrap: wrap;
}

#1,
#2,
#3,
#4,
#5,
#6,
#1 p,
#2 p,
#3 p,
#4 p,
#5 p,
#6 p {
    font-size: 0vh !important;
    opacity: 0 !important;
}

.cv-date {
    user-select: none;
    padding-top: .1%;
    padding-left: .5%;
    padding-right: .5%;
    border-radius: .4vh;
    border: 1px solid var(--main);
    background-color: rgb(140, 20, 252, .3);
    color: white;
    font-weight: bold;
    opacity: .7;
    margin-left: 9px;
    width: fit-content;
    font-size: 80%;
}

.cv-description {
    display: grid;
    color: var(--grey);
}

.cv-description span {
    color: var(--grey);
}

.cv-job {
    color: var(--main);
    font-weight: bold;
}

.cv-list {
    margin-left: auto;
    margin-right: auto;
}

span {
    transition: all.4s;
}

span:hover {
    cursor: pointer;
}

.doc h2 {
    text-align: center;
}

@media only screen and (max-width:900px) {

    .doc {
        padding: 4px;
        padding-bottom: 25px;
    }

    .resume {
        width: 100vw;
        background-position: top center;
        background-size: 259px;
    }

    .cv-txt {
        font-size: 1em;
    }

    #cv-title {
        font-size: 1.7vh;
        margin-bottom: 4vh;
    }

    .doc h2 {
        font-size: 2.5vh !important;
    }

}

@media only screen and (min-width:900px) {

    .resume {
        border: 4px solid var(--main);
        width: 95vw;
        max-width: 1600px;
        background-position: top right;
        background-size: 350px;
    }

    .doc {
        padding: 2vw;
        padding-bottom: 75px;
    }

    .cv-txt {
        font-size: 1.2em;
    }

    #cv-title {
        font-size: 1.5em;
    }

}

#txt-card1,
#txt-card2,
#txt-card3,
#txt-card4 {
    display: none;
}

.flexone {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.qrcode {
    display: grid;
    margin-top: 50px;
    margin-bottom: 50px;
    height: fit-content;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.qrcode img {
    width: 250px;
    max-width: 50vw;
    margin-bottom: 25px;
    height: auto;
    filter: invert(1) contrast(.83);
}

.qrcode a {
    font-size: 1.4em;
}

.resume-a {
    display: block !important;
    margin: 92px auto !important;
    width: fit-content;
    border-radius: 9px;
    background-color: var(--dark);
    transition: all.2s;
}

.resume-btn {
    width: fit-content;
    padding: 9px;
    border-radius: 9px;
    font-size: 1.4em;
    background-color: var(--dark);
    color: var(--main);
    text-align: center;
    border: .2em solid var(--main);
    transition: all.2s;
}

.resume-a:hover .resume-btn {
    cursor: pointer;
    transform: scale(1.1);
    background-color: var(--main);
    color: var(--light);
}

.resume-a:hover {
    background-color: var(--main);
}