#Footer {
    width: 100%;
    height: fit-content;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: grid;
    text-align: center;
    background-color: var(--black);
    box-shadow: 0 0 1em black;
}

#Footer a,
#Footer span {
    text-decoration: none;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: .5em;
}

#Footer a {
    color: var(--grey);
    font-style: italic;
    margin-bottom: 1.5em;
    word-break: break-all;
}

#Footer span {
    color: var(--light);
    font-weight: bold;
    margin-bottom: .5em;
    white-space: break-spaces;
}

#Footer a:hover,
#Footer .medias a:hover {
    text-decoration: underline;
}

#Footer span:hover {
    cursor: default;
}

#Footer p {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5em;
    text-align: center;
    height: .1em;
    background-color: var(--grey);
    border-radius: .1em;
}