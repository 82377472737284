.error {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    vertical-align: middle;
    background-color: black;
    background-position: center;
    filter: hue-rotate(100deg);
    background-repeat: no-repeat;
}

.error h1 {
    position: absolute;
    top: 2vh;
    width: 100vw;
    text-align: center;
}

.error a {
    position: absolute;
    filter: hue-rotate(-100deg);
    bottom: 7.5vh;
    width: 200px;
    left: calc(50vw - 109px);
    text-align: center;
    text-decoration: none;
    padding: 9px;
    background-color: rgba(140, 20, 252, 0.25);
    backdrop-filter: blur(1em);
    color: var(--light);
    border: .1em solid var(--main);
    border-radius: 9px;
    box-shadow: 0 0 2em black;
    font-size: 1.2em;
    transition: all.2s;
}

.error a:hover {
    transform: scale(1.1);
    background-color: var(--main);
}

@media only screen and (min-width: 900px) {
    
    .error {
        background-size: cover;
        background-image: url('../../images/404.jpeg');
    }

}

@media only screen and (max-width: 900px) {
    
    .error {
        background-size: cover;
        background-image: url('../../images/404phone.jpeg');
    }

}