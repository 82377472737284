#Navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 10vh;
    display: flex;
    text-align: center;
    z-index: 4;
    opacity: 1;
    background-color: var(--black);
    backdrop-filter: blur(2em);
    box-shadow: 0 0 1em black;
    transition: all.4s;
}

.titles {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.titles a {
    text-decoration: none;
    line-height: 10vh;
    color: var(--light);
    font-weight: 400;
    width: 25%;
    text-transform: uppercase;
}

@media only screen and (max-width:699px) {

    #Navbar {
        display: flex;
    }

    .notel {
        display: none;
    }

    #hamburger,
    #cross {
        filter: invert(1);
        pointer-events: all !important;
        user-select: all !important;
        position: relative;
        float: right !important;
        margin: 1.5vh;
        height: 7vh !important;
        width: 7vh !important;
        background-color: transparent;
        border: none;
        background-position: center;
        background-repeat: no-repeat;
    }

    #hamburger {
        background-size: 100%;
        background-image: url("../../images/hamburger.png");
    }

    #cross {
        display: none;
        background-size: 50%;
        background-image: url("../../images/cross.png");
    }

    #hamburger:hover,
    #cross:hover {
        cursor: pointer;
    }

    #scrolled {
        display: none;
        position: absolute;
        top: 12vh;
        height: 45vh;
        width: 100%;
        text-align: center;
        background-color: transparent;
        align-items: center;
        vertical-align: middle;
    }

    #scrolled a {
        text-decoration: none;
        color: var(--light);
        font-size: 1.4em;
    }

    .titles {
        width: 100%;
        display: block;
    }

    .titles a {
        display: none;
    }

}

@media only screen and (min-width:700px) {

    #Navbar {
        display: inline-flex;
    }

    #logo {
        margin-left: 25px;
    }

    #hamburger,
    #cross,
    #scrolled {
        display: none;
    }

    .titles a {
        font-size: 1.2em;
        margin-left: auto;
        margin-right: auto;
        transition: all.1s;
    }

    .titles a:hover {
        cursor: pointer;
        color: var(--main);
        border-bottom: .4em solid var(--main);
    }

}

.logo {
    position: absolute;
    top: 2vh;
    left: 2vh;
    height: 6vh;
    width: fit-content;
}

.logo img {
    height: 100%;
    width: auto;
}

@media only screen and (min-width:700px) {
    .nav-active {
        color: var(--main) !important;
        border-bottom: .4em solid var(--main) !important;
    }
}